<template>
	<div class="max-attenders-input">
		<div class="tools-container">
			<BaseInput v-model="value" inputType="number" 
			:form="form" :name="name" :validator="digitsOnly"
			:showErrorMessage="false"/>
			<div class="max-attenders-input__buttons">
				<div @click="minus">
					<span class="iconify" data-icon="uil:minus"></span>
				</div>
				<div @click="plus">
					<span class="iconify" data-icon="uil:plus"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Form from '../../scripts/form';
import BaseInput from './BaseInput.vue';

export default {
	name: 'MaxAttendersInput',
	components: {
		BaseInput,
	},
	model:{
		prop: 'maxAttenders',
		event: 'update:maxAttenders',
	},
	props:{
		maxAttenders: {
			type: [Number, String],
			required: true,
		},
		form: {
			type: Form,
		},
		name: {
			type: String,
		}
	},
    computed: {
        value: {
            get() {
                return this.maxAttenders
            },
            set(newValue) {
                this.$emit('update:maxAttenders', newValue)
            }
        }
    },
	methods: {
		plus(){
			if (typeof this.value == 'string' && this.value == "") {
				this.value = 0
				this.form.fields["maxAttenders"].isError = false
				return
			}
			if (this.form.fields["maxAttenders"].isError) return
            this.value += 1
        },
        minus(){
            if (this.value < 1) return
			if (this.form.fields["maxAttenders"].isError) return
			
            this.value -= 1
        },
        digitsOnly(value){
            let fixed = value.split('').filter(char => {
                return char != '.' && char != ',' && char != '-'
            }).join('')

            return fixed
        }
	},
};
</script>

<style scoped>
.tools-container{
	display: grid;
	grid-template-columns: 74px 106px;
	grid-column-gap: 16px;
}
input {
    width: calc(100% - 12px - 2px);
    padding: 6px 6px 7px 6px;
    font-size: 16px;
    border-radius: 2px;
    border: 1px solid rgb(68, 68, 68);
}

input:focus{
    outline: none;
}

.max-attenders-input__buttons{
	background: white;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
}

.max-attenders-input__buttons > div{
    display: flex;
    justify-content: center;
    align-items:center;
}

.max-attenders-input__buttons > div:active{
	border: 1px solid;
	border-radius: 4px;
	border-color: hwb(212 0% 0%);
    box-shadow: 0px 0 0 2px #cbe3ff;
}

.max-attenders-input__buttons > div:last-child{
    border-left: 1px solid #dbdbdb;
}

.field{
	width: 60px;
}
</style>